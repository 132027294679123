import Config from '../utils/config'

export default {
  context: null,
  async getParams (domaine) {
    const { data } = await this.context.$http.get('/1.0/marqueblanche/parametres/' + domaine, { params: { apikey: Config.API_KEY } })
      .catch(function (error) {
        console.log(error)
        if (error.response) {
          throw (error.response)
        }
      })
    return data
  }
}
