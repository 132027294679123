import Vue from 'vue'
import Vuex from 'vuex'
import position from './position'
import voyage from './voyage'
import loopi from './loopi'

Vue.use(Vuex)
export default new Vuex.Store({
  getters: {},
  actions: {},
  modules: {
    position,
    voyage,
    loopi
  },
  strict: true
})
