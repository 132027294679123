import LoopiAPI from './api/LoopiAPI'
import AddressAPI from './api/AddressAPI'

export default {
  install (Vue) {
    Object.defineProperty(Vue.prototype, '$loopiAPI', {
      get () { return LoopiAPI }
    })
    Object.defineProperty(Vue.prototype, '$addressAPI', {
      get () { return AddressAPI }
    })
  }
}
