import Vue from 'vue'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import { mapActions } from 'vuex'
import WidgetVoyage from '../WidgetVoyage'
import locales from '../locales/locales'
import '../assets/scss/theme.scss'
import store from '../store'
import install from '../install'
import LoopiAPI from '../api/LoopiAPI'
import AddressAPI from '../api/AddressAPI'
import Config from '../utils/config'
// import apiJs from '../plugins/api.js'

Vue.prototype.$http = axios.create({ baseURL: Config.API_URI })

Vue.use(install)
// Vue.use(apiJs)

Vue.use(VueI18n)
const i18n = new VueI18n({ locale: 'fr', messages: locales })

new Vue({
  el: '#loopi-voyage-widget',
  store,
  i18n,
  beforeCreate () {
    AddressAPI.context = this
    LoopiAPI.context = this
  },
  async created () {
    let urlMarqueBlanche = null
    const widgetEl = document.getElementById('loopi-voyage-widget')
    if (widgetEl) {
      urlMarqueBlanche = widgetEl.dataset.url
    }
    if (urlMarqueBlanche) {
      const params = await this.$loopiAPI.getParams(urlMarqueBlanche)
      params.urlMarqueBlanche = urlMarqueBlanche
      this.setWidgetParams(params)
      i18n.locale = params.language
    }
  },
  methods: {
    ...mapActions(['setWidgetParams'])
  },
  render: h => h(WidgetVoyage)
})
