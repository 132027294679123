export const state = () => ({
  position: {},
  autoriserLocalisation: false
})

const getters = {
  maPosition: state => state.position,
  autoriserLocalisation: state => state.autoriserLocalisation
}

const mutations = {
  setPosition (state, position) {
    state.position = position
  },
  setAutoriserLocalisation (state, autoriserLocalisation) {
    state.autoriserLocalisation = autoriserLocalisation
  }
}

export default {
  state,
  getters,
  mutations
}
