<template>
  <div class="field-autocomplete icon-material" :class="'input-autocomplete-' + index">
    <input
      :id="'input-address-' + index"
      type="text"
      autocomplete="off"
      :placeholder="placeholder()"
      :value="point.address"
      @input="search($event.target.value)"
      @keydown.tab="close"
      @keydown.esc="close"
      @keydown.up="up"
      @keydown.down="down"
      @keydown.enter="enter"
      @click="click"
    >
    <ul v-show="showResults" class="autocomplete-results">
      <li
        v-for="(result, key) in results"
        :key="key"
        :class="classes(key, result)"
        class="icon-material cursor-pointer-md"
        @click="select(result)"
        v-html="formatDisplay(result)"
      />
      <li v-if="noResults" class="no-result icon-material">
        {{ $t('aucunResultat') }}
      </li>
    </ul>
    <div
      v-if="showMaPosition && !isModifyingEtape"
      class="action ma-position material-icons"
      @click="getMaPosition"
      @mouseenter="hoverMaPosition = true"
      @mouseout="hoverMaPosition = false"
    >
      my_location
    </div>
    <div v-if="(point.address !== null || points.length > 2) && !isModifyingEtape" class="action remove material-icons" :title="$t('supprimer')" @click="remove">
      close
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Config from '../utils/config'
import _ from 'lodash'
export default {
  props: {
    index: { type: Number, required: true },
    isModifyingEtape: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      selected: false,
      loading: false,
      hoverMaPosition: false,
      selectedIndex: 0,
      noResults: false,
      results: []
    }
  },
  computed: {
    ...mapGetters({
      points: 'points',
      autoriserLocalisation: 'autoriserLocalisation',
      maPosition: 'maPosition'
    }),
    point () {
      if (this.isModifyingEtape) {
        return {}
      }
      return _.clone(this.points[this.index])
    },
    showResults () {
      return this.results.length > 0 || this.noResults
    },
    showMaPosition () {
      if (this.autoriserLocalisation) {
        if (this.hoverMaPosition || (this.index === 0 && this.point.address === null) || this.focus) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    placeholder () {
      if (this.index === 0) {
        return this.$t('jeParsDOu')
      } else if (this.index === this.points.length - 1) {
        return this.$t('jeVaisOu')
      }
      return ''
    },
    getMaPosition () {
      const place = this.point
      place.address = this.maPosition.address.label
      if (this.maPosition.address.city !== null) {
        place.city = this.maPosition.address.city
      } else {
        place.city = this.maPosition.address.name
      }
      place.latLng = { lat: this.maPosition.coords.latitude, lng: this.maPosition.coords.longitude }
      this.$store.commit('setPoint', { point: place, index: this.index })
    },
    remove () {
      if (this.points.length === 2) {
        this.point.address = null
        this.point.latLng = null
        this.point.city = null
        this.$store.commit('setPoint', { point: this.point, index: this.index })
        this.close()
      } else {
        this.$store.commit('removePoint', this.index)
      }
    },
    search (stringToSearch) {
      this.point.address = stringToSearch
      this.selectedIndex = 0
      this.noResults = false
      this.selected = false
      if (this.point.address.length >= 3) {
        this.loading = true
        this.searchAddresses(this.point.address)
      } else {
        this.results = []
      }
    },
    searchAddresses: _.debounce(function (address) {
      this.$addressAPI.search(address)
        .then((results) => {
          this.loading = false
          this.results = results
          let nbCorrespondancesMaxFind = 0
          let correspondanceFind = null
          Config.AUTOCOMPLETE_ADDITIONAL.forEach((item) => {
            const reg = new RegExp(address.split(' ').join('|'), 'gi')
            let nbCorrespondances = 0
            let testReg = item.properties.geocoding.label.match(reg)
            if (testReg) {
              nbCorrespondances += testReg.join('').length
            }
            testReg = item.properties.geocoding.city.match(reg)
            if (testReg) {
              nbCorrespondances += testReg.join('').length
            }
            if (nbCorrespondances > nbCorrespondancesMaxFind) {
              nbCorrespondancesMaxFind = nbCorrespondances
              correspondanceFind = item
            }
          })
          if (correspondanceFind) {
            results.unshift(correspondanceFind)
          }
          if (results.length === 0) {
            this.noResults = true
          }
        })
    }, 200),
    formatDisplay (result) {
      const text = result.properties.geocoding.label
      if (this.point.address) {
        const query = this.point.address.toLowerCase()
        return text.split(' ').map((word) => {
          const charIndex = word.toLowerCase().indexOf(query)
          if (charIndex >= 0) {
            word = this.insertString(word, charIndex, '<strong>')
            word = this.insertString(word, word.length, '</strong>')
          }
          return word
        }).join(' ')
      }
      return text
    },
    insertString (str, index, value) {
      return str.substr(0, index) + value + str.substr(index)
    },
    select (result) {
      const place = this.point
      place.address = result.properties.geocoding.label
      let ville
      if (result.properties.geocoding.city !== null) {
        ville = result.properties.geocoding.city
      } else {
        ville = result.properties.geocoding.name
      }
      if (this.isModifyingEtape) {
        place.adresse = place.address
        place.ville = ville
        place.code_postal = result.properties.geocoding.postcode
        place.insee = result.properties.geocoding.citycode
        place.point = [result.geometry.coordinates[0], result.geometry.coordinates[1]]
        this.$emit('pointAdresse', place)
      } else {
        place.city = ville
        place.latLng = { lat: result.geometry.coordinates[1], lng: result.geometry.coordinates[0] }
        this.$store.commit('setPoint', { point: place, index: this.index })
      }
      this.selected = true
      this.close()
    },
    enter () {
      const result = this.results[this.selectedIndex]
      if (result !== undefined) {
        this.select(result)
      }
    },
    up () {
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.results.length - 1
      } else {
        this.selectedIndex--
      }
    },
    down () {
      if (this.selectedIndex === this.results.length - 1) {
        this.selectedIndex = 0
      } else {
        this.selectedIndex++
      }
    },
    click (e) {
      if (!this.point.address || this.point.address.length === 0) {
        this.results = Config.AUTOCOMPLETE_ADDITIONAL
      } else if (this.point.address && this.point.address.length > 0) {
        e.target.setSelectionRange(0, this.point.address.length)
      }
    },
    close () {
      this.results = []
      this.noResults = false
      if (!this.selected) {
        this.point.address = this.points[this.index].address
      }
    },
    classes (key, feature) {
      const classes = []
      let type
      if (key === this.selectedIndex) {
        classes.push('selected')
      }
      if (Object.prototype.hasOwnProperty.call(feature.properties.geocoding, 'type')) {
        type = feature.properties.geocoding.type
        classes.push(type)
      }
      if (Object.prototype.hasOwnProperty.call(feature.properties.geocoding, 'poi_types')) {
        feature.properties.geocoding.poi_types.forEach((poiType) => {
          let typeId = poiType.id
          if (type === 'poi') {
            typeId = poiType.id.split(':')[2]
          } else if (type === 'sit') {
            switch (typeId) {
              case 'hotels':
              case 'aires-de-camping-car':
              case 'campings':
              case 'chambres-d-hotes':
              case 'gites-et-locations-de-meubles':
              case 'gites-etape':
              case 'hebergements-collectifs':
              case 'hebergements-insolites':
              case 'residences-de-tourisme':
              case 'villages-vacances':
                classes.push('hebergements')
                break
              case 'restaurants':
              case 'bars':
                classes.push('alimentation')
                break
              case 'artisanat':
              case 'musees-sites':
              case 'village-caractere':
              case 'parcs-animaliers':
              case 'expositions':
              case 'sites-monuments':
              case 'lieu-baignade':
              case 'patrimoine-naturel':
              case 'degustation':
              case 'parcs-attraction':
              case 'loisirs-activites':
              case 'activites-sportives':
              case 'marches':
                classes.push('loisirs')
                break
              case 'aires-de-pique-nique':
              case 'location-canoe':
              case 'loueurs-reparateurs-velos':
              case 'office-de-tourisme':
              case 'autres-locations':
                classes.push('infos-pratiques')
                break
            }
          }
          classes.push(typeId)
        })
      }
      if (Object.prototype.hasOwnProperty.call(feature.properties.geocoding, 'zone_type')) {
        classes.push(feature.properties.geocoding.zone_type)
      }
      return classes.join(' ')
    }
  }
}
</script>
