const state = () => ({
  params: null
})

const getters = {
  params: state => state.params
}

const mutations = {
  params (state, params) {
    state.params = params
  }
}

const actions = {
  setWidgetParams ({ commit }, params) {
    commit('params', params)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
