export default {
  // API_URI: process.env.URL_API,
  API_URI: 'https://api.loopi.moustic-multimedia.fr/',
  API_KEY: '4j7gz9d7iQR3BB3PJsp49uJD',
  AUTOCOMPLETE_ADDITIONAL: [
    {
      geometry: { coordinates: [-0.114546, 42.889345] },
      properties: {
        geocoding: {
          label: 'Cauterets Village',
          city: 'Cauterets (65110), Hautes-Pyrénées, Occitanie, France',
          name: 'Cauterets',
          postcode: '65110',
          citycode: '65138'
        }
      }
    },
    {
      geometry: { coordinates: [-0.139763, 42.850836] },
      properties: {
        geocoding: {
          label: 'Cauterets Pont d\'Espagne',
          city: 'Cauterets (65110), Hautes-Pyrénées, Occitanie, France',
          name: 'Cauterets',
          postcode: '65110',
          citycode: '65138'
        }
      }
    },
    {
      geometry: { coordinates: [-0.004341, 42.872114] },
      properties: {
        geocoding: {
          label: 'Luz-Saint-Sauveur Village',
          city: 'Luz-Saint-Sauveur (65120), Hautes-Pyrénées, Occitanie, France',
          name: 'Luz-Saint-Sauveur',
          postcode: '65120',
          citycode: '65295'
        }
      }
    },
    {
      geometry: { coordinates: [-0.058821, 42.885087] },
      properties: {
        geocoding: {
          label: 'Luz-Saint-Sauveur Station Luz Ardiden',
          city: 'Luz-Saint-Sauveur (65120), Hautes-Pyrénées, Occitanie, France',
          name: 'Luz-Saint-Sauveur',
          postcode: '65120',
          citycode: '65295'
        }
      }
    },
    {
      geometry: { coordinates: [-0.332506, 42.957958] },
      properties: {
        geocoding: {
          label: 'Gourette Centre station',
          city: 'Gourette (64440), Pyrénées-Atlantiques, Nouvelle-Aquitaine, France',
          name: 'Gourette',
          postcode: '64440',
          citycode: '64204'
        }
      }
    },
    {
      geometry: { coordinates: [-0.744390, 42.977362] },
      properties: {
        geocoding: {
          label: 'La Pierre Saint Martin Centre station',
          city: 'La Pierre Saint Martin (64570), Pyrénées-Atlantiques, Nouvelle-Aquitaine, France',
          name: 'La Pierre Saint Martin',
          postcode: '64570',
          citycode: '64570'
        }
      }
    },
    {
      geometry: { coordinates: [0.159697, 42.782279] },
      properties: {
        geocoding: {
          label: 'Piau Engaly Centre station',
          city: 'Piau Engaly (65170), Hautes-Pyrénées, Occitanie, France',
          name: 'Piau Engaly',
          postcode: '65170',
          citycode: '65017'
        }
      }
    },
    {
      geometry: { coordinates: [0.477530, 42.788271] },
      properties: {
        geocoding: {
          label: 'Peyragudes Les Agudes',
          city: 'Peyragudes (65510), Hautes-Pyrénées, Occitanie, France',
          name: 'Peyragudes',
          postcode: '65510',
          citycode: '65240'
        }
      }
    },
    {
      geometry: { coordinates: [0.442264, 42.794988] },
      properties: {
        geocoding: {
          label: 'Peyragudes Peyresourde',
          city: 'Peyragudes (65510), Hautes-Pyrénées, Occitanie, France',
          name: 'Peyragudes',
          postcode: '65510',
          citycode: '65240'
        }
      }
    },
    {
      geometry: { coordinates: [0.177675, 42.911918] },
      properties: {
        geocoding: {
          label: 'Grand Tourmalet La Mongie',
          city: 'La Mongie (65200), Hautes-Pyrénées, Occitanie, France',
          name: 'La Mongie',
          postcode: '65200',
          citycode: '65901'
        }
      }
    },
    {
      geometry: { coordinates: [0.0622368, 42.895881] },
      properties: {
        geocoding: {
          label: 'Grand Tourmalet Barèges',
          city: 'Barèges (65120), Hautes-Pyrénées, Occitanie, France',
          name: 'Barèges',
          postcode: '65120',
          citycode: '65481'
        }
      }
    },
    {
      geometry: { coordinates: [0.177675, 42.911918] },
      properties: {
        geocoding: {
          label: 'Pic du Midi Barèges',
          city: 'La Mongie (65200), Hautes-Pyrénées, Occitanie, France',
          name: 'La Mongie',
          postcode: '65200',
          citycode: '65901'
        }
      }
    },
    {
      geometry: { coordinates: [-1.600725, 43.324948] },
      properties: {
        geocoding: {
          label: 'La Rhune Départ train',
          city: 'La Rhune (64310), Pyrénées-Atlantiques, Nouvelle-Aquitaine, France',
          name: 'La Rhune',
          postcode: '64310',
          citycode: '64310'
        }
      }
    }
  ]
}
