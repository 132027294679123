import queryString from 'query-string'
import Config from '../utils/config'

export default {
  context: null,
  async geocodingLatLng (latitude, longitude) {
    const query = { lat: latitude, lon: longitude }
    const { data } = await this.context.$http.get('/1.0/geocoder/reverse?' + queryString.stringify(query), { params: { apikey: Config.API_KEY } })
    return (data.features.length > 0) ? data.features[0] : null
  },
  async search (address, latitude, longitude) {
    const query = { q: address }
    if (latitude && longitude) {
      query.lat = latitude
      query.lon = longitude
    }
    const { data } = await this.context.$http.get('/1.0/geocoder/autocomplete?' + queryString.stringify(query), { params: { apikey: Config.API_KEY } })
    return data.features
  },
  maPosition () {
    // @todo:voir pour state
    let maPosition = null
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(position)
        }, (error) => {
          reject(error)
        })
      })
        .then((position) => {
          maPosition = position
          return this.geocodingLatLng(position.coords.latitude, position.coords.longitude)
        })
        .then((address) => {
          return {
            coords: maPosition.coords,
            address: address.properties.geocoding
          }
        })
    } else {
      return null
    }
  }
}
