export const state = () => ({
  points: [{ maPosition: true, latLng: null, address: null, city: null }, { latLng: null, address: null, city: null }]
})

const getters = {
  points: state => state.points
}

const mutations = {
  setPoint (state, { index, point }) {
    state.points.splice(index, 1, point)
  },
  removePoint (state, index) {
    state.points.splice(index, 1)
  }
}

export default {
  state,
  getters,
  mutations
}
